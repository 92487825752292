var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',[_vm._l((_vm.transformedPointsByObject),function(pointsByObject,i){return _c('x-data-table',{key:i,attrs:{"headers":_vm.headers,"items":pointsByObject.pointList,"caption":i,"use-caption-as-index":true,"use-item-slot":true,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item.item;
return [_c('tr',{staticClass:"data-table__text",on:{"click":function () {
            var point = _vm.sortedPointsByObject[i].pointList.find(
              function (point) { return point['номер'] === +item.deviceId; }
            );

            _vm.chooseAccountingPoint(point, function () { return _vm.$emit('update:slider', true); });
            _vm.openPlanByAccountingPoint(item);
          }}},_vm._l((item),function(value){return _c('td',{key:value,attrs:{"title":value}},[_vm._v(_vm._s(value))])}),0)]}}],null,true)})}),_c('x-slider',{attrs:{"show":_vm.showPlanByAccountingPoint,"container-mod-class":"split-container_width_max","box-mod-class":"split-container__main_with_card","title":("План по точке учета № " + _vm.chosenPointId)},on:{"update:show":function($event){_vm.showPlanByAccountingPoint=$event},"close":_vm.closePlanByAccountingPoint}},[_c('accounting-point-plan-card',{attrs:{"showSlider":_vm.showPlanByAccountingPoint},on:{"close-slider":_vm.closePlanByAccountingPoint}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }