














































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import XCard from "../SimpleCard.vue";
import XDropdownWithRadioButtons from "../hoc/DropdownWithRadioButtons.vue";
import AccountingPointPlanForm from "./AccountingPointPlanForm.vue";
import {mapGetters, mapMutations} from "vuex";
import {
  PlanServicesByAccountingPoint,
  PlanValuesByAccountingPoint
} from "@/models/accounting-point";

@Component({
  components: {
    XCard,
    XDropdownWithRadioButtons,
    AccountingPointPlanForm,
  },
  computed: {
    ...mapGetters({
      pointNumber: "accountingPoint/number",
      pointName: "accountingPoint/name",
      pointServices: 'accountingPoint/pointServices',
      pointPlanValues: 'accountingPoint/pointPlanValues',
    }),
  },
  // methods: {
  //   ...mapMutations("accountingPoint", {
  //     setPlanServices: "setPlanServices",
  //   }),
  // }
})
class AccountingPointPlanCard extends Vue {
  [x: string]: any
  @Prop({ required: true }) readonly showSlider!: boolean;

  chosenYear = this.years[0];
  chosenYearIndex = 0;
  // chosenServiceType = this.serviceTypes[0];
  chosenServiceTypeId = this.serviceTypeIds[0];
  chosenServiceTypeIndex = 0;

  get chosenServiceIndex() {
    return this.chosenServiceTypeIndex
  }

  @Watch('pointPlanValues')
  public setChosenServiceTypeIndex() {
    if (!!this.pointPlanValues && this.pointPlanValues[0]) {
      const service = this.pointPlanValues[0]['услуга']
      const serviceWithPlanIndex = this.serviceTypeIds.indexOf(service)
      if(~serviceWithPlanIndex) {
        this.chosenServiceTypeIndex = serviceWithPlanIndex
        // this.chosenServiceType = this.serviceTypes[serviceWithPlanIndex]
        this.chosenServiceTypeId = this.serviceTypeIds[serviceWithPlanIndex]
      }
    }
  }
  @Watch('chosenYearIndex')
  public resetServices() {
    this.changeChosenYear(this.chosenYearIndex)
  }

  public get years(): number[] {
    const currentYear = new Date().getFullYear();
    return [currentYear, currentYear + 1];
  }

  public get serviceTypes(): string[] {
    return this.pointServices ? this.pointServices.map((service: PlanServicesByAccountingPoint) => {
      const filteredValues = this.pointPlanValues.filter((value: PlanValuesByAccountingPoint) => value.год == this.chosenYear)
      if (filteredValues.map((value: PlanValuesByAccountingPoint) => value.услуга).includes(service.ключ)) {
        return {label: service.значение, class: 'highlighted'}
      }
      return {label: service.значение, class: ''}
    }) : []
  }

  public get serviceTypeIds(): number[] {
    return this.pointServices ? this.pointServices.map((service: PlanServicesByAccountingPoint) => service.ключ) : []
  }

  @Watch("showSlider")
  public showSliderChanged() {
    const defaultValueIndex = 0;

    this.changeChosenYear(defaultValueIndex);
    this.changeChosenServiceType(defaultValueIndex);
  }

  public changeChosenYear(yearIndex: number) {
    this.chosenYear = this.years[yearIndex];
  }

  public changeChosenServiceType(serviceTypeIndex: number) {
    this.chosenServiceTypeIndex = serviceTypeIndex
    // this.chosenServiceType = this.serviceTypes[serviceTypeIndex]
    this.chosenServiceTypeId = this.serviceTypeIds[serviceTypeIndex]
  }
}

export default AccountingPointPlanCard;
